<template>
  <b-modal
    ref="modal"
    id="group-status-modal"
    title="Modifier le statut du groupe"
    ok-title="Sauvegarder"
    cancel-variant="outline-secondary"
    cancel-title="Annuler"
    size="lg"
    @ok.prevent="submitFormData"
  >
    <b-form>
      <b-row>
        <b-col cols="12" class="mb-2">
          <br />
          <h1 class="section-label mx-0 mt-0">Statut du groupe</h1>
        </b-col>
        <b-col cols="6">
          <required-select-box
            :available-options="groupStatusOptions"
            name="Group status"
            label="Groupe"
            label-for="group-status"
            placeholder="Select Value"
            v-model="groupStatus"
          />
        </b-col>
        <b-col cols="6">
          <required-select-box
            :available-options="saleStatusOptions"
            name="Group type"
            label="Vente"
            label-for="sale-Status"
            placeholder="Select Value"
            v-model="saleStatus"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mb-2">
          <br />
          <h1 class="section-label mx-0 mt-0">Publication</h1>
        </b-col>
        <b-col cols="6">
          <required-select-box
            :available-options="accessStatusOptions"
            name="access-status"
            label="Accès"
            label-for="access-status"
            placeholder="Select Value"
            v-model="accessStatus"
          />
        </b-col>

        <b-col v-if="accessStatus === 'RESTRICTED'" cols="6">
          <required-select-box
            :available-options="availableAgencies"
            :multiple="true"
            :close-on-select="false"
            name="agencies"
            label="Agences ayant accès au groupe"
            label-for="restricted-agency"
            placeholder="Veuillez choisir une ou plusieurs agences"
            v-model="allowedAgencies"
          />
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>
<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
import { ref, watch } from "@vue/composition-api/dist/vue-composition-api";
import RequiredSelectBox from "@/shared/components/RequiredSelectBox";
import { useGroupApi } from "@/modules/group/composables/use-group-api";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { useAgencyApi } from "@/modules/agency/composables/use-agency-api";

import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BModal,
  // VBModal,
} from "bootstrap-vue";
export default {
  name: "GroupStatusModal",
  components: {
    ValidationProvider,
    ValidationObserver,
    RequiredSelectBox,
    BButton,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BModal,
    BInputGroupPrepend,
    BRow,
  },
  props: ["currentGroup"],
  setup(props, { emit }) {
    const { updateGroupStatus, updateAccessGroupStatus } = useGroupApi();
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();

    const saleStatus = ref(props.currentGroup.sale_status);
    const groupStatus = ref(props.currentGroup.status);
    const accessStatus = ref(props.currentGroup.access_status);

    const availableAgencies = ref([]);
    const allowedAgencies = ref(props.currentGroup.allowed_agencies);

    watch(
      () => props.currentGroup,
      (updatedCurrentGroup, second) => {
        saleStatus.value = updatedCurrentGroup.sale_status;
        groupStatus.value = updatedCurrentGroup.status;
        accessStatus.value = updatedCurrentGroup.access_status;
      }
    );

    const fetchAgencies = async () => {
      const agencies = await useAgencyApi().listAgencies();
      availableAgencies.value = agencies.map((x) => {
        return { text: x.name, value: x.id };
      });
    };

    fetchAgencies();
    const selectedAgencies = availableAgencies.value.filter((x) => {
      return props.currentGroup.allowed_agencies.includes(x.value);
    });
    allowedAgencies.value.push(...selectedAgencies);

    const submitFormData = async () => {
      try {
        let statusData = {
          status: groupStatus.value,
          saleStatus: saleStatus.value,
        };

        await updateGroupStatus(props.currentGroup.id, statusData);

        let accessData = {
          accessStatus: accessStatus.value,
          allowedAgencies: [],
        };

        if (accessStatus.value === "RESTRICTED") {
          if (Array.isArray(allowedAgencies.value)) {
            accessData.allowedAgencies.push(...allowedAgencies.value);
          } else {
            accessData.allowedAgencies.push(allowedAgencies.value);
          }
        }

        await updateAccessGroupStatus(props.currentGroup.id, accessData);
        displaySuccessMessage(
          "Les informations de statut du groupe ont été sauvegardées avec succès"
        );
        emit("monica-group-saved", {
          status: groupStatus.value,
          saleStatus: saleStatus.value,
          accessStatus: accessStatus.value,
        });
      } catch {
        displayErrorMessage("La sauvegarde d'information du groupe a échoué");
      }
    };

    const saleStatusOptions = [
      { text: "À venir", value: "UPCOMING" },
      { text: "En vente", value: "ON_SALE" },
      { text: "Sur demande", value: "ON_DEMAND" },
      { text: "Complet", value: "FULL" },
      { text: "Fermé", value: "CLOSE" },
    ];

    const groupStatusOptions = [
      { text: "Brouillon", value: "DRAFT" },
      { text: "Publié", value: "PUBLISHED" },
      { text: "Complété", value: "COMPLETED" },
      { text: "Archivé", value: "ARCHIVED" },
      { text: "Annulé", value: "CANCELLED" },
    ];

    const accessStatusOptions = [
      { text: "Privé", value: "UNPUBLISHED" },
      { text: "Publié", value: "PUBLISHED" },
      { text: "Restreint", value: "RESTRICTED" },
    ];

    const submitGroupStatus = () => {
      emit("submitGroupStatus", {});
    };
    return {
      submitGroupStatus,
      saleStatusOptions,
      groupStatusOptions,
      accessStatusOptions,
      saleStatus,
      groupStatus,
      accessStatus,
      submitFormData,
      availableAgencies,
      allowedAgencies,
    };
  },
  data() {
    return {
      required,
    };
  },
  methods: {
    validationForm() {
      this.$nextTick(() => {
        this.$refs["modal"].hide();
      });
    },
  },
};
</script>
